<template>
	<div class="map" ref="map"></div>
	<div v-if="map">
		<AthletePin v-for="athlete in athletes" :key="athlete.userId" :athlete="athlete" @pin-created="onPinCreated" @pin-removed="onPinRemoved" />
	</div>
</template>

<script>
	import AthletePin from "./AthletePin.vue"
	import _ from "lodash"

	export default {
		name: "AthletesMap",
		props: {
			athletes: {
				type: Array,
				required: true,
			},
		},
		components: { AthletePin },
		data: function() {
			return {
				map: null,
			}
		},
		mounted: function() {
			const Microsoft = window.Microsoft
			let mapElement = this.$refs.map

			this.map = new Microsoft.Maps.Map(mapElement, {
				mapTypeId: Microsoft.Maps.MapTypeId.aerial,
				zoom: 16,
			})
		},
		methods: {
			onPinCreated: function(pin) {
				this.map.entities.push(pin)
			},
			onPinRemoved: function(pin) {
				this.map.entities.remove(pin)
			},
			zoomToAthleteCoordinate: function(userId) {
				let athlete = _.find(this.athletes, { userId: userId })

				const Microsoft = window.Microsoft
				this.map.setView({
					center: new Microsoft.Maps.Location(athlete.latitude, athlete.longitude),
					zoom: 18,
				})
			},
		},
	}
</script>

<style scoped>
	.map {
		width: 100%;
		height: 100%;
	}
</style>
