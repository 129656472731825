<template>
	<div class="vw-100 vh-100 d-flex" v-if="ready">
		<div class="athletes-map">
			<AthletesMap :athletes="athletes" ref="athletesmap" />
		</div>
		<div class="athletes-list">
			<div>
				<img class="utpivo-logo" type="image/svg+xml" :src="getUptivoLogo()" />
			</div>
			<hr style="color: whitesmoke" />
			<AthletesList :athletes="athletes" @athlete-clicked="athleteClicked" @reset-metrics="resetMetrics" />
		</div>
	</div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import _ from "lodash"
import moment from "moment"

import PubNub from "pubnub"
import AthletesMap from "./components/AthletesMap.vue"
import AthletesList from "./components/AthletesList.vue"
import { clamp } from "./shared/utils"
import { hrZoneColors } from "./shared/colors.js"

export default {
	name: "App",
	data: function () {
		return {
			ready: false,
			pubnub: null,
			athletes: [],
			saveTimerId: null,
			updateTimerId: null,
		}
	},
	components: {
		AthletesMap,
		AthletesList,
	},
	methods: {
		setupBingMaps() {
			// Add a global function for the callback from Bing Maps api
			window.OnLoadBingMapsApi = () => this.initBingMaps()

			// Add programmaticaly the external Bing maps api script
			let mapScriptTag = document.createElement("script")
			mapScriptTag.src =
				"https://www.bing.com/api/maps/mapcontrol?callback=OnLoadBingMapsApi&key=AjPZbcNa0hmKKDDgBpUsJGLZyqu7EcpsxaQLo3TZmRh4X9-OHaJYfo9U6BkeEz-x"
			mapScriptTag.async = true
			mapScriptTag.defer = true

			// Inject the dynamic script in the DOM
			document.head.appendChild(mapScriptTag)
		},
		initBingMaps() {
			this.ready = true
			delete window.OnLoadBingMapsApi

			console.log("BING MAPS READY!")
		},
		setupPubNub() {
			this.pubnub = new PubNub({
				publishKey: "pub-c-133dc5e6-03ff-4a0a-aecb-f9b05803f338",
				subscribeKey: "sub-c-a9fbb99a-c477-11eb-9e40-ea6857a81ff7",
				uuid: "UptivoGeoLocClient",
			})

			this.pubnub.addListener({
				status: (statusEvent) => console.log("PUBNUB STATUS", statusEvent),
				signal: this.handlePubNubMessage,
			})
			this.pubnub.subscribe({ channels: ["uptivo-maps"] })

			console.log("PUBNUB READY!")
		},
		handlePubNubMessage(payload) {
			const msg = this.parsePayload(payload)
			if (msg) {
				let athlete = _.find(this.athletes, { userId: msg.userId })
				if (athlete) {
					this.updateAthlete(athlete, msg)
				} else {
					athlete = this.createAthlete(msg)
					this.athletes.push(athlete)

					fetch(`https://api.uptivo.fit/internalapi/users/${athlete.userId}/avatar`, {
						headers: {
							"X-Secret": "%!!fsRY&5th-]]fSzcHTy468GdGH<q",
						},
					})
						.then((response) => response.json())
						.then((data) => {
							athlete = _.find(this.athletes, {
								userId: msg.userId,
							})
							if (athlete) {
								athlete.nickName = data.Nickname
								if (data.PictureUri) {
									athlete.pictureUri = data.PictureUri
								}
								
							}

							this.setSaveTimer()
						})
						.catch((error) => console.error(error))
				}

				this.setSaveTimer()
			}
		},
		parsePayload(payload) {
			try {
				const msg = payload.message
				const pusher = payload.publisher.split("-")
				const userId = pusher[1]

				return {
					userId: parseInt(userId),
					latitude: msg.lt,
					longitude: msg.lg,
					heartRate: clamp(msg.hr, 0, 220),
					heartRatePrc: clamp(msg.hrp, 0, 150),
					heartZone: clamp(msg.z, 0, hrZoneColors.length - 1),
				}
			} catch (e) {
				console.error("ERROR PARSING PAYLOAD", payload, e)
			}
		},
		createAthlete(msg) {
			const now = moment().valueOf()

			let athlete = {
				userId: msg.userId,
				nickName: "Athlete " + msg.userId,
				pictureUri: require("@/assets/images/profile-white.png"),
				distance: 0,
				duration: 0,
				totalPace: 0,
				pace: 0,
				paceData: _.fill(Array(6), { ts: now, d: 0 }),
			}

			this.updateAthlete(athlete, msg)

			return athlete
		},
		updateAthlete(athlete, msg) {
			const now = moment().valueOf()

			if (!_.isUndefined(athlete.latitude) && !_.isUndefined(athlete.longitude)) {
				athlete.distance += this.pointsDistance(athlete, msg)
			}

			if (!_.isUndefined(athlete.lastUpdate) && now > athlete.lastUpdate) {
				athlete.duration += now - athlete.lastUpdate
			}

			// Update athlete data

			// duration [ms] / distance [m] = (duration / 60000) [min] / (distance / 1000) [km] = (duration * 1000) [min] / (distance * 60000) [km] = duration [min] / (60 * distance) [km]
			// pace [min]/[km]
			athlete.totalPace =
				athlete.distance > 0 && athlete.duration > 5000 ? athlete.duration / (60 * athlete.distance) : 0

			athlete.latitude = msg.latitude
			athlete.longitude = msg.longitude
			athlete.heartRate = msg.heartRate
			athlete.heartRatePrc = msg.heartRatePrc
			athlete.heartZone = msg.heartZone

			athlete.lastUpdate = now
		},
		athleteClicked(userId) {
			this.$refs.athletesmap.zoomToAthleteCoordinate(userId)
		},
		pointsDistance(p1, p2) {
			const degToRad = function (x) {
				return (x * Math.PI) / 180
			}
			const earthRadiusMt = 6378137

			var dLat = degToRad(p2.latitude - p1.latitude)
			var dLong = degToRad(p2.longitude - p1.longitude)
			var a =
				Math.sin(dLat / 2) * Math.sin(dLat / 2) +
				Math.cos(degToRad(p1.latitude)) *
					Math.cos(degToRad(p2.latitude)) *
					Math.sin(dLong / 2) *
					Math.sin(dLong / 2)
			return earthRadiusMt * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
		},
		getUptivoLogo() {
			var images = require.context("./assets/images", false, /\.svg$/)
			return images("./uptivo-logo.svg")
		},
		loadData() {
			this.athletes = JSON.parse(localStorage.getItem("athletes")) || []
		},
		saveData() {
			localStorage.setItem("athletes", JSON.stringify(this.athletes))
		},
		resetMetrics() {
			this.athletes = []
			this.saveData()
		},
		setPaceTimer() {
			if (!this.paceTimerId) {
				this.paceTimerId = setInterval(this.paceTimerHandler, 10 * 1000)
			}
		},
		paceTimerHandler() {
			_.each(this.athletes, function (athlete) {
				const endData = {
					ts: athlete.lastUpdate,
					d: athlete.distance,
				}
				const startData = athlete.paceData.shift()
				athlete.paceData.push(endData)

				const deltaDuration = endData.ts - startData.ts
				const deltaDist = endData.d - startData.d

				athlete.pace =
					deltaDist > 0 ? deltaDuration / (60 * deltaDist) : 0
			})
		},
		setSaveTimer() {
			if (!this.saveTimerId) {
				this.saveTimerId = setTimeout(this.saveTimerHandler, 10 * 1000)
			}
		},
		saveTimerHandler() {
			this.saveTimerId = null
			this.saveData()
		},
	},
	mounted: function () {
		this.setupPubNub()
		this.setupBingMaps()
		this.loadData()
		this.setPaceTimer()
	},
}
</script>

<style>
#app {
	font-family: "Montserrat", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	user-select: none;
}

.athletes-map {
	height: 100%;
	width: 70%;
}

.athletes-list {
	height: 100%;
	width: 30%;

	overflow-y: auto;
	padding-top: 0.5vw;
	background-color: #3b3838;
}

.utpivo-logo {
	width: 12vw;
}

/* latin-ext */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/montserrat/v14/JTUQjIg1_i6t8kCHKm45_QpRxy7mw9c.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/montserrat/v14/JTUQjIg1_i6t8kCHKm45_QpRyS7m.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_aZA3gfD_u50.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_aZA3gnD_g.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_cJD3gfD_u50.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_cJD3gnD_g.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gfD_u50.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gnD_g.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gfD_u50.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gnD_g.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gfD_u50.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_c5H3gfD_u50.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_c5H3gnD_g.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_epG3gfD_u50.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_epG3gnD_g.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
</style>
