<script>
	import { HR_ZONE_COLORS } from "./HeartRateZones"

	export default {
		name: "AthletePin",
		props: {
			athlete: {
				type: Object,
				required: true,
			},
		},
		emits: ["pin-created", "pin-removed"],
		data: function() {
			return {
				pin: null,
			}
		},
		render: function() {
			return null
		},
		beforeUnmount() {
			this.$emit("pin-removed", this.pin)
		},
		methods: {
			updateAthlete(athleteData) {
				const Maps = window.Microsoft.Maps
				const location = new Maps.Location(athleteData.latitude, athleteData.longitude)
				const options = {
					title: athleteData.nickName,
					subTitle: athleteData.heartRatePrc + "%",
					color: HR_ZONE_COLORS[athleteData.heartZone],
				}

				if (!this.pin) {
					this.pin = new Maps.Pushpin(location, options)
					this.$emit("pin-created", this.pin)
				} else {
					this.pin.setLocation(location)
					this.pin.setOptions(options)
				}
			}
		},
		computed: {
			athleteData() {
				return {
					nickName: this.athlete.nickName,
					latitude: this.athlete.latitude,
					longitude: this.athlete.longitude,
					heartRatePrc: this.athlete.heartRatePrc,
					heartZone: this.athlete.heartZone
				}
			}
		},
		watch: {
			athleteData: {
				handler: function(ad) { this.updateAthlete(ad) },
				immediate: true
			},
		},
	}
</script>
