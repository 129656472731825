<template>
	<div class="athlete-box m-1 px-1 d-flex justify-content-between" :style="{ 'background-color': backgroundColor }">
		<div class="align-self-center athlete-avatar">
			<img v-bind:src="athlete.pictureUri" class="rounded-circle" />
		</div>
		<div class="align-self-center mx-2 flex-grow-1 athlete-nickname">
			{{ athlete.nickName }}
		</div>
		<div class="align-self-center mx-1 athlete-info pace" v-if="showExtra">
			<div class="main">{{ pace }}</div>
			<div class="secondary">
				<span>{{ totalPace }}</span>
				<span class="small">&nbsp;/Km</span>
			</div>
		</div>
		<div class="align-self-center mx-1 athlete-info distance" v-if="showExtra">
			<div class="main">
				<span>{{ (athlete.distance * 0.001).toFixed(1) }}</span>
			</div>
			<div class="secondary">
				<span>Km</span>
			</div>
		</div>
		<div class="align-self-center me-1 athlete-info heart-rate">
			<div class="main">
				<span>{{ athlete.heartRatePrc }}</span>
				<span class="small">%</span>
			</div>
			<div class="secondary">
				<span>{{ athlete.heartRate }}</span
				>&nbsp;<span class="small">bpm</span>
			</div>
		</div>
	</div>
</template>

<script>
import { HR_ZONE_COLORS } from "./HeartRateZones"
import moment from "moment"

export default {
	props: {
		athlete: {
			type: Object,
			required: true,
		},
		showExtra: {
			type: Boolean,
			required: true,
		},
	},
	computed: {
		backgroundColor() {
			return HR_ZONE_COLORS[this.athlete.heartZone]
		},
		pace() {
			if (this.athlete.pace > 0) {
                var duration = moment.duration(this.athlete.pace, 'minutes')
				return duration.minutes() + ":" + duration.seconds().toString().padStart(2, '0')
			}

            return "0:00"
		},
		totalPace() {
			if (this.athlete.totalPace > 0) {
                var duration = moment.duration(this.athlete.totalPace, 'minutes')
				return duration.minutes() + ":" + duration.seconds().toString().padStart(2, '0')
			}

            return "0:00"
		},
	},
}
</script>

<style scoped lang="scss">
@import "bootstrap/scss/vendor/_rfs.scss";

.athlete-box {
	// @include font-size(3rem);
	color: #fff;
	border-radius: 6px;
	cursor: pointer;
	font-family: "Montserrat";
	border: 0.2vw solid transparent;
}

.athlete-box:hover {
	opacity: 0.8;
	border: 0.2vw solid white;
	cursor: pointer;
}

.athlete-avatar img {
	width: 2.8vw;
	height: 2.8vw;
	border: 0.1vw solid white;
}

.athlete-nickname {
	text-align: left;
	font-size: 2.0vw;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.athlete-info .main {
	font-size: 1.6vw;
	line-height: 1.6vw;
}

.athlete-info .secondary {
	font-size: 0.8vw;
	line-height: 0.8vw;
}

.athlete-info .small {
	font-size: 50%;
	line-height: 50%;
}

.athlete-info {
	min-width: 3.8vw;
}

.athlete-info.pace {
	min-width: 4.5vw;
}

</style>
