<template>
	<div class="search-wrapper d-flex justify-content-center" style="margin-bottom: 15px; margin-top: 10px">
		<label><i class="fa fa-search" style="color: white"></i></label>&nbsp;
		<input type="text" v-model="searchText" style="border-radius: 5px" />&nbsp;
		<span @click="resetSearchText" v-if="searchText != ''">
			<i class="fa fa-times-circle" style="cursor: pointer; color: whitesmoke"></i>
		</span>
	</div>
	<div
		class="zones-filter-wrapper d-flex justify-content-between align-self-center"
		style="margin: 5px 5px 0.5vw 5px"
	>
		<div
			class="circle-zone-filter rounded-circle"
			style="border: 0.08vw solid whitesmoke"
			@click="searchZone = -1"
			:class="{ zoneSelected: searchZone == -1 }"
		>
			<div style="font-size: 0.7vw; line-height: 1.8vw; font-weight: bold; color: whitesmoke; user-select: none">
				ALL
			</div>
		</div>
		<div
			v-for="(zoneColor, index) in hrZonesColors"
			:key="index"
			class="rounded-circle circle-zone-filter"
			:style="{ 'background-color': zoneColor }"
			@click="setSearchZone(zoneColor)"
			:class="{ zoneSelected: searchZone == indexOfColorZone(zoneColor) }"
		></div>
	</div>
	<hr style="color: whitesmoke; margin-bottom: 0.3vw" />
	<div class="d-flex justify-content-between" style="margin: 5px 5px 5px 5px; margin-bottom: 0.5vw">
		<div @click="resetMetrics" style="cursor: pointer; line-height: 0.8vw; font-size: 0.8vw">
			<span class="show-distance-text">Reset:</span>&nbsp;<i
				style="color: whitesmoke"
				class="fa fa-times-circle"
			></i>
		</div>
		<div @click="toggleExtra" style="cursor: pointer; line-height: 0.8vw; font-size: 0.8vw">
			<span class="show-distance-text">Extra:</span>&nbsp;
			<span v-if="showExtra">
				<i style="color: whitesmoke" class="fa fa-toggle-on"></i>
			</span>
			<span v-if="!showExtra">
				<i style="color: whitesmoke" class="fa fa-toggle-off"></i>
			</span>
		</div>
	</div>

	<div
		class="px-1 d-flex justify-content-between"
		style="
			background-color: whitesmoke;
			font-size: 1.1vw;
			border-radius: 5px;
			margin: 5px 5px 5px 5px;
			user-select: none;
		"
	>
		<div class="athlete-avatar"></div>
		<div class="align-self-center mx-2 flex-grow-1 athlete-nickname">
			<div @click="sortByNickname" style="cursor: pointer">
				<span v-if="nickNameOrder == ''">
					<i style="color: grey" class="fa fa-sort"></i>
				</span>
				<span v-if="nickNameOrder == 'asc'">
					<i style="color: #f3712a" class="fa fa-caret-up"></i>
				</span>
				<span v-if="nickNameOrder == 'desc'">
					<i style="color: #f3712a" class="fa fa-caret-down"></i>
				</span>
				<span style="margin-left: 0.2vw">NickName</span>
			</div>
		</div>

		<div class="align-self-center mx-1 athlete-info pace" v-if="showExtra">
			<div @click="sortByPace" style="cursor: pointer">
				<span v-if="paceOrder == ''">
					<i style="color: grey" class="fa fa-sort"></i>
				</span>
				<span v-if="paceOrder == 'asc'">
					<i style="color: #f3712a" class="fa fa-caret-up"></i>
				</span>
				<span v-if="paceOrder == 'desc'">
					<i style="color: #f3712a" class="fa fa-caret-down"></i>
				</span>
				<span style="margin-left: 0.2vw">Pace</span>
			</div>
		</div>

		<div class="align-self-center mx-1 athlete-info distance" v-if="showExtra">
			<div @click="sortByDistance" style="cursor: pointer">
				<span v-if="distanceOrder == ''">
					<i style="color: grey" class="fa fa-sort"></i>
				</span>
				<span v-if="distanceOrder == 'asc'">
					<i style="color: #f3712a" class="fa fa-caret-up"></i>
				</span>
				<span v-if="distanceOrder == 'desc'">
					<i style="color: #f3712a" class="fa fa-caret-down"></i>
				</span>
				<span style="margin-left: 0.2vw">Dist</span>
			</div>
		</div>

		<div class="align-self-center mx-1 athlete-info heart-rate">
			<div @click="sortByHrPrc" style="cursor: pointer">
				<span v-if="prcOrder == ''">
					<i style="color: grey" class="fa fa-sort"></i>
				</span>
				<span v-if="prcOrder == 'asc'">
					<i style="color: #f3712a" class="fa fa-caret-up"></i>
				</span>
				<span v-if="prcOrder == 'desc'">
					<i style="color: #f3712a" class="fa fa-caret-down"></i>
				</span>
				<span style="margin-left: 0.2vw">Prc</span>
			</div>
		</div>
	</div>

	<div v-if="athletes.length">
		<AthleteBox
			v-for="athlete in filteredAthletes"
			:key="athlete.userId"
			:athlete="athlete"
			:showExtra="showExtra"
			@click="$emit('athlete-clicked', athlete.userId)"
		/>
	</div>
</template>

<script>
import AthleteBox from "./AthleteBox.vue"
import { HR_ZONE_COLORS } from "./HeartRateZones"
import _ from "lodash"
export default {
	name: "AthletesList",
	props: {
		athletes: {
			type: Array,
			required: true,
		},
	},
	components: { AthleteBox },
	emits: ["athlete-clicked", "reset-metrics"],
	data: function () {
		return {
			searchText: "",
			searchZone: -1,
			showExtra: true,
			nickNameOrder: "",
			distanceOrder: "",
			paceOrder: "",
			prcOrder: "",
		}
	},
	methods: {
		resetSearchText() {
			this.searchText = ""
		},
		setSearchZone: function (zoneColor) {
			this.searchZone = HR_ZONE_COLORS.indexOf(zoneColor)
		},
		indexOfColorZone(zoneColor) {
			return HR_ZONE_COLORS.indexOf(zoneColor)
		},
		toggleExtra() {
			this.showExtra = !this.showExtra
			this.distanceOrder = ""
			this.paceOrder = ""
		},
		sortByNickname() {
			this.nickNameOrder = this.nickNameOrder == "asc" ? "desc" : "asc"
			this.distanceOrder = ""
			this.paceOrder = ""
			this.prcOrder = ""
		},
		sortByDistance() {
			this.nickNameOrder = ""
			this.distanceOrder = this.distanceOrder == "asc" ? "desc" : "asc"
			this.paceOrder = ""
			this.prcOrder = ""
		},
		sortByPace() {
			this.nickNameOrder = ""
			this.distanceOrder = ""
			this.paceOrder = this.paceOrder == "asc" ? "desc" : "asc"
			this.prcOrder = ""
		},
		sortByHrPrc() {
			this.nickNameOrder = ""
			this.distanceOrder = ""
			this.paceOrder = ""
			this.prcOrder = this.prcOrder == "asc" ? "desc" : "asc"
		},
		resetMetrics() {
			this.$emit("reset-metrics")
		},
	},
	computed: {
		filteredAthletes() {
			var filteredAthletes = this.athletes.filter((a) => {
				return a.nickName.toLowerCase().includes(this.searchText.toLowerCase())
			})

			if (this.searchZone != -1) {
				filteredAthletes = filteredAthletes.filter((a) => {
					return a.heartZone == this.searchZone
				})
			}

			if (this.nickNameOrder != "") {
				filteredAthletes = _.orderBy(filteredAthletes, [athlete => athlete.nickName.toLowerCase()], this.nickNameOrder)
			}
			if (this.distanceOrder != "") {
				filteredAthletes = _.orderBy(filteredAthletes, ["distance"], this.distanceOrder)
			}
			if (this.paceOrder != "") {
				filteredAthletes = _.orderBy(filteredAthletes, ["totalPace"], this.paceOrder)
			}
			if (this.prcOrder != "") {
				filteredAthletes = _.orderBy(filteredAthletes, ["heartRatePrc"], this.prcOrder)
			}

			return filteredAthletes
		},
		hrZonesColors() {
			return HR_ZONE_COLORS
		},
	},
}
</script>

<style scoped>
.athlete-box {
	margin-bottom: 5px;
}

.search-wrapper {
	font-size: 1vw;
}

.circle-zone-filter {
	width: 2vw;
	height: 2vw;
	cursor: pointer;
	opacity: 0.8;
}

.circle-zone-filter:hover {
	opacity: 1;
	border: 0.15vw solid whitesmoke;
	width: 2.2vw;
	height: 2.2vw;
}

.zoneSelected {
	opacity: 1;
	border: 0.15vw solid whitesmoke !important;
	width: 2.2vw;
	height: 2.2vw;
}

.zones-filter-wrapper {
	padding-top: 5px;
}

.show-distance-text {
	color: whitesmoke;
	font-size: 0.7vw;
	user-select: none;
}

.athlete-avatar {
	width: 2.8vw;
}

.athlete-nickname {
	text-align: left;
}

.athlete-info {
	min-width: 3.8vw;
}

.athlete-info.pace {
	min-width: 4.5vw;
}

</style>
